<template>
	<p
		v-if="text"
		:class="classes"
	>
		{{ text }}
	</p>
	<p
		v-else-if="hasChildren"
		:class="classes"
	>
		<slot />
	</p>
	<p
		v-else-if="!hasChildren && !text"
		:class="classes"
	>
		No content provided.
	</p>
</template>

<script setup lang="ts">
type Props = {
	text?: string;
	class?: string;
};

const slots = useSlots();

const hasChildren = computed(() => Object.keys(slots).length > 0);

const props = defineProps<Props>();

const classes = `text-lg ${props.class || ""}`;
</script>
